import React, { useEffect, useState } from 'react'
import { Paper, Grid } from '@material-ui/core'
import {
  AddCircleOutline,
  Delete as CustomDeleteIcon,
} from '@material-ui/icons'
import { Form } from 'components/form'
import { Delete } from 'components/resource/Delete'
import { useEmbeddedCollectionFetch } from './_helpers/useEmbeddedCollectionFetch'
import { constants, reducer } from './_state'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import schema from '_schema/event'
import { notification } from '../../../_helpers/notification'
import moment from 'moment'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import buildUrl from 'build-url'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    borderBottom: `1px solid ${theme.elements.background}`,
  },
  massExpand: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    '& > *': {
      marginRight: 15,
    },
  },
  addContainerColor: {
    backgroundColor: theme.elements.embedded_collection.background,
    color: theme.elements.embedded_collection.color,
  },
  collectionContainer: {
    position: 'relative',
    overflowX: 'inherit',
    marginBottom: 20,
  },
  cell: {
    verticalAlign: 'middle',
    borderBottom: 'none',
  },
  itemContainer: {
    display: 'block',
  },
  emptyResults: {
    textAlign: 'left',
  },
  draggedRow: {
    display: 'block',
    background: theme.elements.content,
    '& td': {
      display: 'inline-block',
      borderBottom: 'none',
    },
    zIndex: 1400,
  },
  contextualHint: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  expansionPanelSummary: {
    display: 'flex',
    alignItems: 'center',
  },
  itemTitle: {
    flexGrow: 3,
    marginLeft: 10,
  },
  itemIcon: {
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
  expandIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
  },

  datesContainer: {
    background: 'transparent',
  },
  datesGridContainer: {
    padding: '24px',
  },
  datesGridItem: {
    background: 'transparent',
  },
  datesGridFormContainer: {
    background: 'transparent',
    justifyContent: 'space-between',
  },
  datesItem: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '24px',
    width: '33%',
  },
  root: {
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    '& > :nth-child(1), & > :nth-child(2)': {
      flex: '1 1 auto',
    },
    '& > :nth-child(3)': {
      flexBasis: '100%',
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '0',
    },
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#5A6169',
  },
  formSeparatorContainer: {
    width: '100%',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      height: '1px',
      backgroundColor: '#9EA9B5',
      width: '100%',
    },
  },
}))

export const DatesCollection = ({
  endpoint,
  pid,
  parentIri,
  properties: customProperties,
  definitionSchema,
  definitions,
  headerTitle = null,
  expandTitle = null,
  panelTitle = null,
  titleAccessor = null,
  statable = false,
  statDisabled = false,
  statAccessor = 'stat',
  deleteDisabled = false,
  contextualHint = null,
  disabled = false,
  maxItems = null,
  classes = {},
  additionalTitleVarTitle = null,
  additionalTitleVar = null,
  disableTableBorder = false,
  modalResponse = null,
  setModalResponse = null,
  addButtonTitle = '',
  hideAddButton = false,
  addButtonSmall = false,
  orderable = true,
  order = 'asc',
  hidePanelTitle = false,
  modifiedCallback = null,
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(reducer, endpoint, pid)

  const [stateCycle, dispatchCycle] = useEmbeddedCollectionFetch(
    reducer,
    schema.subresources.cycle.endpointCollection,
    pid
  )

  const defaultClasses = useStyles()

  const handleDelete = resource => {
    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })

    if (modifiedCallback) {
      modifiedCallback(resource)
    }
  }

  const handleDeleteFromCycle = resource => {
    dispatchCycle({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  useEffect(() => {
    if (modalResponse && setModalResponse) {
      dispatch({ type: constants.ADD_ITEM, payload: { value: modalResponse } })
      setModalResponse(null)
    }
  }, [dispatch, modalResponse, setModalResponse])

  const handleAddDateSuccess = resource => {
    dispatch({
      type: constants.ADD_ITEM,
      payload: { value: resource, order: 'asc' },
    })

    notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
  }

  //<CircularProgress />

  const [isCycleReloading, setIsCycleReloading] = useState(false)

  const handleGenerateCycleSuccess = response => {
    const controller = new AbortController()
    const { signal } = controller

    const endpointSplit = schema.subresources.cycle.endpointCollection.split(
      '?'
    )
    const url = buildUrl(endpointSplit[0], {
      queryParams: {
        'iri[parent]': parentIri,
      },
    })

    setIsCycleReloading(true)

    fetchDataHandleAuthError(
      `${url}${endpointSplit[1] ? '&' + endpointSplit[1] : ''}`,
      'GET',
      { signal },
      response => {
        dispatchCycle({
          type: constants.FETCH_SUCCESS,
          payload: { items: response['hydra:member'] },
        })
        setIsCycleReloading(false)

        notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
      },
      error => {
        setIsCycleReloading(false)
        if (error.response.title === 'AbortError') {
          return
        }

        dispatchCycle({ type: constants.FETCH_FAILURE })
        notification('error', error.response.error, 'Wystąpił błąd')
      }
    )
  }

  return (
    <>
      {state?.isFetching ? (
        <CircularProgress />
      ) : (
        <Paper variant="outlined" className={defaultClasses.datesContainer}>
          <Grid
            container
            spacing={3}
            className={defaultClasses.datesGridContainer}
          >
            <Grid
              item
              xs={6}
              className={defaultClasses.datesGridItem}
              style={{ borderRight: 'solid 1px #9EA9B5' }}
            >
              <div>Daty</div>
              <Form
                definitionSchema={definitionSchema}
                properties={schema.subresources.dates.properties}
                method="POST"
                url={endpoint}
                defaultData={{ parent: parentIri }}
                storeCollectionId={schema.endpoint}
                showSubmitAndStayButton={false}
                showCancelButton={false}
                handleSuccess={handleAddDateSuccess}
                width={600}
                classes={defaultClasses}
                customSubmitButtonIcon={<AddCircleOutline />}
                customSubmitButtonTitle={'Dodaj datę'}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={defaultClasses.datesGridItem}
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              {state?.items.length > 0 ? (
                state.items.map((item, i) => {
                  return (
                    <div
                      key={`additional-date-${i}`}
                      className={defaultClasses.datesItem}
                    >
                      <span>
                        {moment(new Date(item?.dateFrom)).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                        {item?.duration && ` (${item?.duration}g.)`}
                      </span>
                      <Delete
                        resource={item}
                        accessor={'dateFrom'}
                        disabled={state.isFetching}
                        isIcon={true}
                        onSuccess={handleDelete}
                        customIcon={
                          <CustomDeleteIcon
                            className={defaultClasses.deleteIcon}
                          />
                        }
                      />
                    </div>
                  )
                })
              ) : (
                <div>Brak dodatkowych dat</div>
              )}
            </Grid>
          </Grid>
          <div className={defaultClasses.formSeparatorContainer}>
            <div></div>
          </div>
          <Grid
            container
            spacing={3}
            className={defaultClasses.datesGridContainer}
          >
            <Grid
              item
              xs={6}
              className={defaultClasses.datesGridItem}
              style={{ borderRight: 'solid 1px #9EA9B5' }}
            >
              <div>Daty</div>
              <Form
                definitionSchema={definitionSchema}
                properties={schema.subresources.cycle.properties}
                method="POST"
                url={schema.subresources.cycle.endpoint}
                defaultData={{ parent: parentIri }}
                storeCollectionId={schema.subresources.cycle.endpointCollection}
                showSubmitAndStayButton={false}
                showCancelButton={false}
                handleSuccess={handleGenerateCycleSuccess}
                width={600}
                classes={defaultClasses}
                customSubmitButtonIcon={<AddCircleOutline />}
                customSubmitButtonTitle={'Dodaj powtórzenia'}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className={defaultClasses.datesGridItem}
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              {isCycleReloading ||
                (stateCycle.isFetching && <CircularProgress />)}
              {stateCycle?.items.length > 0 &&
                !isCycleReloading &&
                !stateCycle.isFetching &&
                stateCycle.items.map((item, i) => {
                  return (
                    <div
                      key={`additional-date-${i}`}
                      className={defaultClasses.datesItem}
                    >
                      <span>
                        {moment(new Date(item?.dateFrom)).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                        {item?.duration && ` (${item?.duration}g.)`}
                      </span>
                      <Delete
                        resource={item}
                        accessor={'dateFrom'}
                        disabled={stateCycle.isFetching}
                        isIcon={true}
                        onSuccess={handleDeleteFromCycle}
                        customIcon={
                          <CustomDeleteIcon
                            className={defaultClasses.deleteIcon}
                          />
                        }
                      />
                    </div>
                  )
                })}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}

DatesCollection.propTypes = {
  endpoint: PropTypes.string.isRequired,
  parentIri: PropTypes.string.isRequired,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
      properties: PropTypes.object,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }),
      ]),
      hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      validate: PropTypes.arrayOf(PropTypes.string),
      endpoint: PropTypes.string,
      thumbs: PropTypes.shape({
        endpoint: PropTypes.string.isRequired,
      }),
      choices: PropTypes.object,
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string.isRequired,
        })
      ),
      titleAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func,
      ]),
    })
  ).isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  headerTitle: PropTypes.string,
  expandTitle: PropTypes.string,
  panelTitle: PropTypes.string,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  orderable: PropTypes.bool,
  statable: PropTypes.bool,
  statDisabled: PropTypes.bool,
  statAccessor: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  contextualHint: PropTypes.string,
  disabled: PropTypes.bool,
  disableTableBorder: PropTypes.bool,
  modalResponse: PropTypes.object,
  setModalResponse: PropTypes.func,
  addButtonTitle: PropTypes.string,
  hideAddButton: PropTypes.bool,
  addButtonSmall: PropTypes.bool,
  classes: PropTypes.shape({
    header: PropTypes.string,
    massExpand: PropTypes.string,
    addContainer: PropTypes.string,
    collectionContainer: PropTypes.string,
    cell: PropTypes.string,
    expansionPanelDetails: PropTypes.string,
    emptyResults: PropTypes.string,
    draggedRow: PropTypes.string,
    contextualHint: PropTypes.string,
    expansionPanelSummary: PropTypes.string,
    itemTitle: PropTypes.string,
    itemIcon: PropTypes.string,
    expandIcon: PropTypes.string,
  }),
  hidePanelTitle: PropTypes.bool,
}
