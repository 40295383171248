import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
// eslint-disable-next-line no-unused-vars
import { MenuItem, Select } from '@material-ui/core'
import { translate } from '../../../_helpers/translate'

const useStyles = makeStyles(theme => ({
  input: {
    width: '300px',
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
  input_horizontal: {
    width: 200,
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      padding: 5,
    },
  },
  autocomplete_input: {
    height: 40,
    padding: '5px !important',
  },
  label: {
    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
  },
  row_input: {
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 9,
    marginBottom: 10,
    '& .MuiInputBase-input': {
      paddingTop: '4px !important',
    },
    '& fieldset': {
      border: 0,
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
      maxHeight: 46,
    },
  },
  wrapper: {
    padding: '0px 4px',
  },
  select: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    height: 46,
    marginTop: 10,
    color: theme.elements.choice_filter.color,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
}))

export const ChoiceFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
  choices = [],
  titleAccessor = 'title',
}) => {
  const handleChange = e => {
    const value = e.target.value
    setFilter(name, value || null)
  }

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.wrapper)}>
      <Select
        id={'choice-filter-' + name}
        onChange={handleChange}
        value={Object.keys(choices).length ? value ?? '' : ''}
        defaultValue={Object.keys(choices).length ? value ?? '' : ''}
        className={clsx(defaultClasses.select)}
        disableUnderline
        displayEmpty
      >
        <MenuItem value="">{translate('T_GENERAL_ALL')}</MenuItem>
        {Object.keys(choices).map(key => (
          <MenuItem value={key} key={key}>
            {translate(choices[key])}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

ChoiceFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  choices: PropTypes.object,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  titleAccessor: PropTypes.string,
}
