import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/news'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { modifyPropertiesByPage } from '../Pages/_helpers/modifyPropertiesByPage'
import { addOpenformProperties } from '../Pages/_helpers/addOpenformProperties'
import { applyProperties } from '../../_helpers/applyProperties'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [parentResource, setParentResource] = useState(null)

  const defaultProperties = JSON.parse(
    JSON.stringify(schema.resource.properties)
  )

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={'100%'}
      modifyProperties={(properties, resource, schema) => {
        if (!parentResource) {
          return properties
        }

        let newProperties = JSON.parse(JSON.stringify(properties))

        newProperties = applyProperties(
          newProperties,
          defaultProperties,
          'missing'
        )

        if (!parentResource?.autoNewsClass) {
          return newProperties
        }

        for (const key in newProperties.translations.properties) {
          if (
            newProperties.translations.properties.hasOwnProperty(key) &&
            !['title', 'alt', 'shortLead'].includes(key)
          ) {
            delete newProperties.translations.properties[key]
          }
        }

        for (const key in newProperties) {
          if (
            newProperties.hasOwnProperty(key) &&
            ['adminCanEdit', 'categories', 'seoMedia'].includes(key)
          ) {
            delete newProperties[key]
          }
        }

        return newProperties
      }}
      setParentResource={setParentResource}
    >
      {!parentResource?.autoNewsClass && (
        <BlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={schema.subresources.block.types}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          key={iri}
        />
      )}
    </Form>
  )
}
