import React, { useState } from 'react'
import { Paper } from 'components/Paper'
import { Tab, Tabs } from '@material-ui/core'
import { Title } from '../../components/Title'
import { translate } from '../../_helpers/translate'
import { Orders } from './Orders'
import { ClientOrders } from './ClientOrders'
import { Clients } from './Clients'
import { ActiveClients } from './ActiveClients'
import { ProductPurchases } from './ProductPurchases'
import { ProductViews } from './ProductViews'
import { Deliveries } from './Deliveries'

export const Home = () => {
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Paper>
      <Title>{translate('T_MODULE_SHOP_REPORT')}</Title>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Tab label="Zamówienia" />
        <Tab label="Zamówienia klientów" />
        <Tab label="Zarejestrowani klienci" />
        <Tab label="Aktywni klienci (zarejestrowani)" />
        <Tab label="Zakupione" />
        <Tab label="Dostawa" />
        <Tab label="Wyświetlenia" />
      </Tabs>
      {tabValue === 0 && <Orders></Orders>}
      {tabValue === 1 && <ClientOrders></ClientOrders>}
      {tabValue === 2 && <Clients></Clients>}
      {tabValue === 3 && <ActiveClients></ActiveClients>}
      {tabValue === 4 && <ProductPurchases></ProductPurchases>}
      {tabValue === 5 && <Deliveries></Deliveries>}
      {tabValue === 6 && <ProductViews></ProductViews>}
    </Paper>
  )
}
