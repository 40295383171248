import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { NavLink } from 'react-router-dom'
import { Badge, ListItem, ListItemIcon } from '@material-ui/core'
import { translate } from '_helpers/translate'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.elements.menu.text,
    '& *': {
      color: theme.elements.menu.text,
    },
  },
  active: {
    color: theme.elements.menu.active,
    '& *': {
      color: theme.elements.menu.active,
    },
  },
  listItem: {
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      backgroundColor: theme.elements.menu.hover,
    },
  },
  listItemIcon: {
    minWidth: 44,
  },
  icon: {
    fontSize: 22,
  },
  badge: {
    '& .MuiBadge-badge': {
      color: '#FFFFFF',
      fontWeight: 700,
    },
  },
}))

export const MenuItem = ({ item, exact = false, classes = {} }) => {
  const defaultClasses = useStyles()
  const profile = useSelector(state => state.profile)

  const IconComponent = item.icon

  if (!item) {
    return <span style={{ color: 'red' }}>{'<config error>'}</span>
  }

  return (
    <NavLink
      to={item.path}
      className={clsx(defaultClasses.link, classes.link)}
      activeClassName={clsx(defaultClasses.active, classes.active)}
      exact={exact || (item.path.length > 1 ? false : !!item.exact)}
    >
      <ListItem
        button
        className={clsx(defaultClasses.listItem, classes.listItem)}
      >
        <ListItemIcon
          className={clsx(defaultClasses.listItemIcon, classes.listItemIcon)}
        >
          <IconComponent
            color="inherit"
            className={clsx(defaultClasses.icon, classes.icon)}
          />
        </ListItemIcon>
        {['/releases', '/deleted_users', '/volunteering_submissions'].includes(
          item?.path
        ) ? (
          <div>
            {translate(item.title)}
            <Badge
              overlap="rectangular"
              badgeContent={
                item?.path === '/releases'
                  ? profile?.unreadChangelogEntries
                  : item?.path === '/deleted_users'
                  ? profile?.deletedUsers
                  : item?.path === '/volunteering_submissions'
                  ? profile?.unreadVolunteeringSubmissions
                  : 66
              }
              color="error"
              style={{
                marginBottom: '11px',
                marginLeft: '15px',
              }}
              className={clsx(defaultClasses.badge, classes.link)}
            ></Badge>
          </div>
        ) : (
          <div>{translate(item.title)}</div>
        )}
      </ListItem>
    </NavLink>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  exact: PropTypes.bool,
  classes: PropTypes.shape({
    link: PropTypes.string,
    active: PropTypes.string,
    listItem: PropTypes.string,
    listItemIcon: PropTypes.string,
    icon: PropTypes.string,
  }),
}
