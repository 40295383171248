import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { Link } from 'react-router-dom'
import AddOutlined from '@material-ui/icons/AddOutlined'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    color: theme.palette.disabled,
  },
  confirmText: {
    marginTop: 12,
  },
  smallContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& span': {
      color: '#5A6169',
      fontSize: 14,
      paddingLeft: 10,
    },
  },
  smallDeleteIcon: {
    cursor: 'pointer',
    fontSize: 18,
  },
  smallDeleteIconLabel: {
    cursor: 'pointer',
    paddingLeft: 18,
  },
}))

const styles = () => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
})

export const SmallAddButton = ({
  resource = null,
  title = 'Brak tytułu',
  disabled = false,
  onSuccess = null,
  icon = null,
  path = null,
  useParentPid = false,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Link
        to={`${path}?pid=${
          useParentPid ? resource?.parent?.uuid : resource?.uuid
        }`}
        className={classes.smallContainer}
      >
        <AddOutlined></AddOutlined>
        <span>{translate('T_MODULE_PAGES_ADD_SUBPAGE')}</span>
      </Link>
    </div>
  )
}

SmallAddButton.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  icon: PropTypes.object,
  path: PropTypes.string,
  useParentPid: PropTypes.bool,
}
