import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { translate } from '../../../_helpers/translate'
import { Alert } from '@material-ui/lab'
import { validate } from '../../../_helpers/validate'
import { makeStyles } from '@material-ui/styles'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Switch,
} from '@material-ui/core'
import clsx from 'clsx'
import randomHash from 'random-hash'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginTop: 20,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 10,
    verticalAlign: 'middle',
  },
  hint: {
    color: theme.palette.text.secondary,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ShopOrderFvType = ({
  name,
  variant,
  severity,
  value,
  label,
  hint,
  validators,
  setValue,
  setError,
  initialValue,
  disabled,
  classes = {},
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.checked
    setValue(name, value)
    validateField(name, value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    if (value === null && [undefined, null].includes(initialValue)) {
      setValue(name, false)
    }
  }, [value, setValue, name, initialValue])

  const defaultClasses = useStyles()

  return (
    <FormControl
      className={clsx(defaultClasses.root, classes.root)}
      disabled={disabled}
    >
      {value && (
        <Alert name={name} variant={variant} severity={severity}>
          {translate(label)}
        </Alert>
      )}
      <InputLabel
        htmlFor={id}
        className={clsx(defaultClasses.label, classes.label)}
      >
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Switch
        id={id}
        name={name}
        checked={value || false}
        onChange={handleChange}
        color="primary"
      />
      <FormHelperText className={clsx(defaultClasses.hint, classes.hint)}>
        {hint}
      </FormHelperText>
    </FormControl>
  )

  if (value) {
    return (
      <Alert name={name} variant={variant} severity={severity}>
        {translate(label)}
      </Alert>
    )
  }

  return null
}

ShopOrderFvType.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  initialValue: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
  }),
}
