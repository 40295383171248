import React from 'react'
import { SvgIcon } from '@material-ui/core'

function Trash(props) {
  return (
    <SvgIcon {...props} width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        d="M4.5 3.5V2C4.5 1.44772 4.94772 1 5.5 1H9.5C10.0523 1 10.5 1.44772 10.5 2V3.5M0 4H15M1.5 4V14C1.5 14.5523 1.94772 15 2.5 15H12.5C13.0523 15 13.5 14.5523 13.5 14V4M7.5 7.5V12.5M4.5 9.5V12.5M10.5 9.5V12.5"
        stroke="#E30000"
        style={{ color: 'transparent' }}
      />
    </SvgIcon>
  )
}

export default Trash
