import {
  banner1,
  linkshome,
  newsletter,
  newshome,
  videohome,
  trailhome,
  eventhome,
  shophome,
  shortcuts,
  goodtoknown,
  homeattraction,
  homegetmore,
  homeawaa,
  droplabs,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockAnchorEndpoint,
  blockFileEndpoint,
  blockLinkEndpoint,
  blockLinkThumbEndpoint,
  blockListItemEndpoint,
  blockListItemThumbEndpoint,
) => ({
  types: [
    homeawaa('Sekcja z warunkami pogodowymi'),
    newshome('Sekcja z aktualnościami'),
    shortcuts(blockLinkEndpoint, 'Sekcja "Na skróty"'),
    trailhome('Sekcja "Proponowane trasy"'),
    eventhome('Sekcja z wydarzeniami'),
    videohome(photoEndpoint, 'Sekcja moduł wideo'),
    banner1(
      photoEndpoint,
      blockThumbEndpoint,
      'Sekcja z jednym banerem/video i guzikiem'
    ),

    linkshome(blockLinkEndpoint, 'Sekcja z linkami "Dowiedz się więcej"'),
    shophome('Sekcja z bestselerami'),

    goodtoknown(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      '#PageBlockListItem',
      'Sekcja "Dobrze wiedzieć" - 4 taby'
    ),

    homeattraction(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      '#PageBlockListItem',
      'Sekcja atrakcje'
    ),
    homegetmore(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      '#PageBlockListItem',
      'Sekcja "Dowiedz się więcej" - 3 kafle'
    ),

    newsletter(
      blockListItemEndpoint,
      '#PageBlockListItem',
      'Sekcja z newsletterem'
    ),
    droplabs(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      '#PageBlockListItem',
      'Sekcja droplabs'
    ),
  ],
})

export default schema
