import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/shopProduct'
import routes from './routes'
import { applyProperties } from '../../_helpers/applyProperties'
import { EmbeddedCollection } from '../../components/embedded'
import { translate } from '../../_helpers/translate'
import { AddModal } from '../../components/embedded/AddModal'
import { Alert } from '@material-ui/lab'
import { LANGS_IRI } from '../../_lib/langs'
import { Paper } from '../../components/Paper'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  alert: {
    fontWeight: 700,
    fontSize: '16px',
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const defaultData = { parent: iri }

  const classes = useStyles()

  const [parentResource, setParentResource] = useState(null)
  const [modifiedProperties, setModifiedProperties] = useState({})

  useEffect(() => {
    let newProperties = JSON.parse(
      JSON.stringify(schema.subresources.options.properties)
    )
    if (parentResource) {
      if (parentResource.mainVariant) {
        newProperties.mainVariantOption.endpoint = newProperties.mainVariantOption.endpoint_base.replace(
          ':id',
          parentResource?.mainVariant['@id']
        )
      }

      if (
        parentResource.secondaryVariant &&
        newProperties.secondaryVariantOption
      ) {
        newProperties.secondaryVariantOption.endpoint = newProperties.secondaryVariantOption.endpoint_base.replace(
          ':id',
          parentResource?.secondaryVariant['@id']
        )
      }

      if (!parentResource.mainVariant) {
        delete newProperties.mainVariantOption
      }

      if (!parentResource.secondaryVariant) {
        delete newProperties.secondaryVariantOption
      }

      if (!parentResource?.subscription) {
        delete newProperties.subscriptionCount
      }
    }

    newProperties = applyProperties(
      newProperties,
      definitions[schema.subresources.options.definition].properties,
      'missing'
    )

    setModifiedProperties(newProperties)
  }, [definitions, iri, parentResource])

  const reviewProperties = useMemo(() => {
    return applyProperties(
      schema.subresources.reviews.properties,
      definitions[schema.subresources.reviews.definition].properties,
      'missing'
    )
  }, [definitions])

  const [modalResponse, setModalResponse] = useState(null)

  const [reviewModalResponse, setReviewModalResponse] = useState(null)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      modifyProperties={(properties, resource) => {
        if (!resource?.subscription) {
          delete properties.subscriptionFrom
          delete properties.sender
        }

        return properties
      }}
      sidebar={true}
      width={600}
      setParentResource={setParentResource}
    >
      {parentResource?.variantsCount === 0 && (
        <Alert
          variant="filled"
          severity="warning"
          style={{ marginBottom: '20px' }}
        >
          {translate('T_MODULE_SHOP_PRODUCT_VARIANTS_EMPTY')}
        </Alert>
      )}
      <Paper
        variant="outlined"
        header={translate('T_MODULE_SHOP_PRODUCT_VARIANTS_HEADER_TITLE')}
        withPadding={false}
      >
        <Alert
          variant="filled"
          severity="info"
          style={{ marginBottom: '5px' }}
          className={classes.alert}
        >
          {translate('T_MODULE_SHOP_PRODUCT_VARIANTS_INFORMATION')}
        </Alert>
        <EmbeddedCollection
          endpoint={`${schema.subresources.options.endpoint}?iri[parent]=${iri}&order[ord]=asc&pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={modifiedProperties}
          definitionSchema={definitions[schema.subresources.options.definition]}
          headerTitle={translate('T_MODULE_SHOP_PRODUCT_VARIANTS_HEADER_TITLE')}
          expandTitle={translate('T_MODULE_SHOP_VARIANT_OPTIONS_EXPAND_TITLE')}
          panelTitle={translate('T_MODULE_SHOP_PRODUCT_VARIANT_OPTION_TITLE')}
          titleAccessor={`collectionTitle`}
          statable={true}
          modalResponse={modalResponse}
          setModalResponse={setModalResponse}
          hideAddButton={true}
        />
        <AddModal
          title={translate('T_MODULE_SHOP_PRODUCT_VARIANT_NEW_BUTTON')}
          definitionSchema={definitions[schema.subresources.options.definition]}
          itemProperties={modifiedProperties}
          defaultData={defaultData}
          endpoint={schema.subresources.options.endpoint}
          itemSchema={schema}
          setModalResponse={setModalResponse}
        />
      </Paper>
      <Paper
        variant="outlined"
        header={translate('T_MODULE_SHOP_PRODUCT_INFORMATION_HEADER')}
        withPadding={false}
        style={{ marginTop: '30px' }}
      >
        <EmbeddedCollection
          endpoint={`${schema.subresources.information.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.information.properties}
          definitionSchema={
            definitions[schema.subresources.information.definition]
          }
          headerTitle={translate(
            'T_MODULE_SHOP_PRODUCT_INFORMATION_TITLE_HEADER'
          )}
          panelTitle={translate('T_MODULE_SHOP_PRODUCT_INFORMATION_ITEM_TITLE')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
          addButtonTitle={'T_MODULE_SHOP_PRODUCT_INFORMATION_NEW_BUTTON'}
          addButtonSmall={true}
        />
      </Paper>

      <Paper
        variant="outlined"
        header={translate('T_MODULE_SHOP_PRODUCT_IMAGES_TITLE_HEADER')}
        withPadding={false}
        style={{ marginTop: '30px' }}
      >
        <EmbeddedCollection
          endpoint={`${schema.subresources.images.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.images.properties}
          definitionSchema={definitions[schema.subresources.images.definition]}
          headerTitle={translate('T_MODULE_SHOP_PRODUCT_IMAGES_TITLE_HEADER')}
          panelTitle={translate('T_MODULE_SHOP_PRODUCT_MEDIA')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
          addButtonTitle={'T_GENERAL_IMAGE_ADD'}
          addButtonSmall={true}
        />
      </Paper>
      <Paper
        variant="outlined"
        header={translate('T_MODULE_SHOP_PRODUCT_FILES_TITLE_HEADER')}
        withPadding={false}
        style={{ marginTop: '30px' }}
      >
        <Alert
          variant="filled"
          severity="info"
          style={{ marginBottom: '5px' }}
          className={classes.alert}
        >
          {translate('T_MODULE_SHOP_PRODUCT_FILE_ALERT_INFO')}
        </Alert>
        <EmbeddedCollection
          endpoint={`${schema.subresources.files.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.files.properties}
          definitionSchema={definitions[schema.subresources.files.definition]}
          headerTitle={translate('T_MODULE_SHOP_PRODUCT_FILES_TITLE_HEADER')}
          panelTitle={translate('T_MODULE_SHOP_PRODUCT_FILE')}
          titleAccessor={`translations.${LANGS_IRI.PL}.title`}
          statable={true}
          addButtonTitle={'T_GENERAL_FILE_ADD'}
          addButtonSmall={true}
        />
      </Paper>
      <Paper
        variant="outlined"
        header={translate('T_MODULE_SHOP_PRODUCT_REVIEWS_HEADER_TITLE')}
        withPadding={false}
        style={{ marginTop: '30px' }}
      >
        <EmbeddedCollection
          endpoint={`${schema.subresources.reviews.endpoint}?iri[parent]=${iri}&order[ord]=asc&pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.reviews.properties}
          definitionSchema={definitions[schema.subresources.reviews.definition]}
          headerTitle={translate('T_MODULE_SHOP_PRODUCT_REVIEWS_HEADER_TITLE')}
          panelTitle={translate('T_MODULE_SHOP_PRODUCT_REVIEW_OPTION_TITLE')}
          titleAccessor={`titleDetailed`}
          statable={true}
          modalResponse={reviewModalResponse}
          setModalResponse={setReviewModalResponse}
          hideAddButton={true}
        />
        <AddModal
          title={translate('T_MODULE_SHOP_PRODUCT_REVIEW_NEW_BUTTON')}
          definitionSchema={definitions[schema.subresources.reviews.definition]}
          itemProperties={reviewProperties}
          defaultData={defaultData}
          endpoint={schema.subresources.reviews.endpoint}
          itemSchema={schema}
          setModalResponse={setReviewModalResponse}
        />
      </Paper>
    </Form>
  )
}
