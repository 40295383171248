import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { translate } from '../../../../_helpers/translate'
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '-8px',
  },
  icon: {
    position: 'absolute',
    marginLeft: '55px',
    color: '#39D4BE',
  },
  tooltipIconContainer: {
    height: '40px',
    width: '40px',
    backgroundColor: '#4872C9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hoverIcon: {
    color: '#fff',
    backgroundColor: '#4872C9',
  },
  tooltipContainer: {
    borderRadius: 3,
    border: 'solid 1px #C4CBD8',
    zIndex: 5000,
    backgroundColor: '#fff',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-30px',
  },
  tooltipText: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}))

export const InvoiceCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  translateAccessor = false,
}) => {
  const history = useHistory()
  const defaultClasses = useStyles()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const [showTooltip, setShowTooltip] = useState(false)
  const overEvent = () => {
    setShowTooltip(true)
  }

  const outEvent = () => {
    setShowTooltip(false)
  }

  return (
    <div onClick={onClick} className={defaultClasses.container}>
      <span>
        {translate(
          prop(resource, accessor)
            ? 'T_FORM_FIELD_BOOLEAN_ON'
            : 'T_FORM_FIELD_BOOLEAN_OFF'
        )}
      </span>
      {prop(resource, accessor) && (
        <>
          <CheckCircleOutlined
            className={defaultClasses.icon}
            onMouseOver={() => overEvent()}
          />
          {showTooltip && (
            <div
              className={defaultClasses.tooltipContainer}
              onMouseOut={() => outEvent()}
            >
              <span className={defaultClasses.tooltipText}>Zawiera FV</span>
              <div className={defaultClasses.tooltipIconContainer}>
                <CheckCircleOutlined className={defaultClasses.hoverIcon} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

InvoiceCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
  translateAccessor: PropTypes.bool,
}
