import { amber, blue, grey, red, green } from '@material-ui/core/colors'
import { createTheme, alpha } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'
import typography from './theme/typography'
import overrides from './theme/overrides'

const LOCALES = {
  pl: plPL,
}

const COLORS = {
  BLACK: '#18191b',
  GRAY: {
    DARK_1: '#212529',
    DARK_2: '#2b303c',
    DARK_3: '#424242',
    DARK_4: '#474747',
    DARK_5: '#5a6169',
    DARK_6: '#979FA8',
    LIGHT_1: '#aaa',
    LIGHT_2: '#bdbdbd',
    LIGHT_3: '#eef0f4',
    LIGHT_4: '#fafbfc',
    LIGHT_5: '#E9ECEF',
  },
  WHITE: '#fff',
  PRIMARY: '#476ECC',
  SECONDARY: '#5A6169',
  DISABLED: '#F2F2F2',
  SUCCESS: '#1AD5BE',
  INFO: '#3AC8DC',
  WARNING: '#EBAD41',
  ERROR: '#FF7279',
}

const common = {
  login: {
    logo: COLORS.GRAY.DARK_2,
    submit: {
      color: COLORS.WHITE,
      background: COLORS.GRAY.DARK_2,
    },
  },
  logo: COLORS.BLACK,
  sideBar: {
    background: COLORS.GRAY.DARK_2,
    text: COLORS.GRAY.LIGHT_1,
    hover: COLORS.WHITE,
    divider: COLORS.GRAY.DARK_3,
  },
  menu: {
    text: COLORS.GRAY.LIGHT_1,
    hover: alpha(COLORS.WHITE, 0.05),
    active: COLORS.WHITE,
  },
  subMenu: COLORS.GRAY.DARK_1,
}

const dark = {
  typography,
  overrides,
  palette: {
    type: 'dark',
    primary: {
      ...amber,
      main: amber[500],
    },
    secondary: {
      ...blue,
      main: blue[500],
    },
    disabled: COLORS.GRAY.LIGHT_2,
    error: {
      main: red[400],
    },
    success: {
      main: green[400],
    },
    info: {
      main: blue[400],
    },
  },
  elements: {
    ...common,
    login: {
      ...common.login,
      text: 'rgba(255, 255, 255, .7)',
    },
    topBar: COLORS.GRAY.DARK_3,
    content: COLORS.GRAY.DARK_3,
    background: grey[600],
    title: COLORS.GRAY.LIGHT_3,
    table: {
      odd: COLORS.GRAY.DARK_4,
    },
    header: {
      background: COLORS.GRAY.DARK_1,
      color: COLORS.WHITE,
    },
    tab: {
      header: {
        background: COLORS.GRAY.DARK_1,
        color: COLORS.WHITE,
      },
      body: {
        background: COLORS.GRAY.DARK_5,
        color: COLORS.BLACK,
      },
    },
    tooltip: {
      background: COLORS.BLACK,
      color: COLORS.WHITE,
    },
    embedded_collection: {
      background: amber[500],
      color: COLORS.BLACK,
    },
    messenger: {
      recipient: {
        background: COLORS.GRAY.DARK_4,
      },
      timestamp: {
        color: COLORS.GRAY.DARK_5,
      },
    },
    remember_me: 'rgba(255, 255, 255, .7)',
    table_order_by: COLORS.GRAY.LIGHT_2,
    choice_filter: {
      color: COLORS.GRAY.LIGHT_3,
    },
    multi_status: {
      color: COLORS.GRAY.LIGHT_3,
    },
    columns_settings: {
      backgroundColor: 'transparent',
      tableBackgroundColor: grey[600],
      checkedColor: COLORS.WARNING,
      cogColor: COLORS.WARNING,
    },
  },
}

const light = {
  typography,
  overrides,
  palette: {
    type: 'light',
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    disabled: COLORS.DISABLED,
    error: {
      main: COLORS.ERROR,
    },
    success: {
      main: COLORS.SUCCESS,
    },
    info: {
      main: COLORS.INFO,
    },
    warning: {
      main: COLORS.WARNING,
    },
  },
  elements: {
    ...common,
    login: {
      ...common.login,
      text: 'rgba(0, 0, 0, .54)',
    },
    topBar: COLORS.WHITE,
    content: COLORS.WHITE,
    background: COLORS.GRAY.LIGHT_3,
    title: COLORS.GRAY.DARK_2,
    table: {
      odd: COLORS.GRAY.LIGHT_4,
      tHead: COLORS.DISABLED,
      tHeadC: COLORS.GRAY.DARK_6,
    },
    header: {
      background: COLORS.GRAY.DARK_5,
      color: COLORS.GRAY.LIGHT_5,
    },
    tab: {
      header: {
        background: COLORS.GRAY.LIGHT_4,
        color: COLORS.DARK_5,
      },
      body: {
        background: COLORS.WHITE,
        color: COLORS.BLACK,
      },
    },
    tooltip: {
      background: COLORS.WHITE,
      color: COLORS.BLACK,
    },
    embedded_collection: {
      background: COLORS.PRIMARY,
      color: COLORS.WHITE,
    },
    messenger: {
      recipient: {
        background: COLORS.GRAY.LIGHT_3,
      },
      timestamp: {
        color: COLORS.GRAY.DARK_6,
      },
    },
    remember_me: 'rgba(0, 0, 0, .54)',
    table_order_by: COLORS.DARK_6,
    choice_filter: {
      color: COLORS.GRAY.DARK_6,
    },
    multi_status: {
      color: COLORS.SECONDARY,
    },
    columns_settings: {
      backgroundColor: COLORS.GRAY.LIGHT_4,
      tableBackgroundColor: COLORS.GRAY.LIGHT_4,
      checkedColor: COLORS.PRIMARY,
      cogColor: COLORS.DARK_6,
    },
  },
}

export const theme = type =>
  createTheme(
    type === 'dark' ? dark : light,
    LOCALES[process.env.REACT_APP_LOCALE]
  )
