import React from 'react'
import { SvgIcon } from '@material-ui/core'

function Tourney(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ fontSize: 18 }}
    >
      <path
        d="M5.5 19H14.5M10 19V12.5714M10 12.5714C12.8403 12.5714 15.1429 10.2689 15.1429 7.42857V2.28571C15.1429 1.57563 14.5672 1 13.8571 1H6.14286C5.43278 1 4.85714 1.57563 4.85714 2.28571V7.42857C4.85714 10.2689 7.15968 12.5714 10 12.5714ZM4.85714 3.57143H3.57143C2.15127 3.57143 1 4.7227 1 6.14286C1 7.56302 2.15127 8.71429 3.57143 8.71429H4.85714M15.1429 3.57143H16.4286C17.8487 3.57143 19 4.7227 19 6.14286C19 7.56302 17.8487 8.71429 16.4286 8.71429H15.1429"
        stroke="#AAAAAA"
        strokeWidth="2"
        style={{ color: 'transparent' }}
      />
    </SvgIcon>
  )
}

export default Tourney
