import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  switch_track: {
    backgroundColor: theme.palette.error.main,
  },
  switch_base: {
    color: theme.palette.error.main,
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: theme.palette.success.main,
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
  ok: {
    color: 'inherit',
  },
  highlighted: {
    backgroundColor: theme.palette.action.hover,
  },
  active: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  switch_container: {
    display: 'flex',
    alignItems: 'center',
  },
  switch_label: {
    color: '#5A6169',
    paddingLeft: 5,
  },
}))

export const SmallStatus = ({
  resource = null,
  accessor = 'stat',
  disabled = false,
  onSuccess = null,
  translations = {
    publish: 'T_GENERAL_PUBLISH',
    unpublish: 'T_GENERAL_UNPUBLISH',
  },
  isSwitch = true,
  handleSyncUpdatedAt = null,
  customEndpoint = '',
}) => {
  const [status, setStatus] = useState(prop(resource, accessor))
  const [isFetching, setIsFetching] = useState(false)
  const classes = useStyles()
  const handleChange = () => {
    if (disabled) {
      return
    }

    setStatus(!status)
    setIsFetching(true)
    fetchDataHandleAuthError(
      customEndpoint
        ? customEndpoint.replace('@id', resource?.uuid)
        : resource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          [accessor]: !status,
        }),
      },
      resource => {
        handleSyncUpdatedAt && handleSyncUpdatedAt(resource)
        setIsFetching(false)
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(!status)
      },
      error => {
        setStatus(status)
        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return isSwitch ? (
    <div className={classes.switch_container}>
      <Switch
        name={`${resource['@id']}-status`}
        onChange={handleChange}
        disabled={disabled || isFetching}
        checked={status}
        color="primary"
        size="small"
        classes={{
          track: classes.switch_track,
          switchBase: classes.switch_base,
          colorPrimary: classes.switch_primary,
        }}
      />
      <span className={classes.switch_label}>
        {translate(status ? translations.publish : translations.unpublish)}
      </span>
    </div>
  ) : (
    <div className={clsx(disabled && classes.disabled)}>
      {status ? (
        <>
          <span className={classes.highlighted}>
            {translate(translations.publish)}
          </span>{' '}
          /&nbsp;
          <span
            className={clsx(
              disabled ? classes.disabled : classes.ok,
              !disabled && classes.active
            )}
            onClick={handleChange}
          >
            {translate(translations.unpublish)}
          </span>
        </>
      ) : (
        <>
          <span
            className={clsx(classes.disabled, !disabled && classes.active)}
            onClick={handleChange}
          >
            {translate(translations.publish)}
          </span>
          &nbsp; /{' '}
          <span className={classes.highlighted}>
            {translate(translations.unpublish)}
          </span>
        </>
      )}
    </div>
  )
}

SmallStatus.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  accessor: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  translations: PropTypes.shape({
    publish: PropTypes.string.isRequired,
    unpublish: PropTypes.string.isRequired,
  }),
  isSwitch: PropTypes.bool,
  handleSyncUpdatedAt: PropTypes.func,
  customEndpoint: PropTypes.string,
}
