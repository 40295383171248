import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { prop } from '../../../_helpers/prop'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    paddingLeft: 6,
    paddingRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
  inputRow: {
    width: '100%',
    paddingLeft: 6,
    paddingRight: 4,
    marginTop: 9,
    marginBottom: 10,
    '& input': {
      height: '44px',
      padding: 0,
      paddingLeft: 10,
      paddingRight: 4,
      border: 'solid 1px #E7E7E7',
      borderRadius: 0,
      backgroundColor: theme.elements.background,
    },
    '& fieldset': {
      border: 0,
      borderRadius: 0,
    },
  },
  container: {
    display: 'flex',
  },
}))

export const RangeFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  titles = [],
  filterState,
  classes = {},
}) => {
  const [value1, setValue1] = useState(null)
  const [value2, setValue2] = useState(null)

  const handleChange1 = e => handleChange(e, 1)
  const handleChange2 = e => handleChange(e, 2)

  const handleChange = (e, input = 1) => {
    let newValue = e.target.value
    let val1 = null
    let val2 = null
    const filterName = name.replace(/\[.*?\]/g, '')

    if (input === 1) {
      setValue1(newValue)
      val1 = newValue
      val2 = value2
    } else {
      setValue2(newValue)
      val1 = value1
      val2 = newValue
    }

    let filter = null

    if (val1 === '' || val1 === null) {
      filter = 'lte'
    } else if (val2 === '' || val2 === null) {
      filter = 'gte'
    } else {
      filter = 'between'
    }

    const filtersToClean = ['gte', 'lte', 'between'].filter(f => f !== filter)
    filtersToClean.forEach(f => setFilter(`${filterName}[${f}]`, null))

    if (filter === 'between') {
      if (val1 !== null && val2 !== null && val1 !== '' && val2 !== '') {
        newValue = [val1, val2].join('..')
      }
    }

    setFilter(`${filterName}[${filter}]`, newValue || null)

    //setFilter(name, newValue || null)
  }

  const getCurrentValue1 = () => {
    return getCurrentValue(1)
  }

  const getCurrentValue2 = () => {
    return getCurrentValue(2)
  }

  const getCurrentValue = (input = 1) => {
    const filterName = name.replace(/\[.*?\]/g, '')
    if (prop(filterState, `${filterName}[between].value`)) {
      const [val1, val2] = prop(
        filterState,
        `${filterName}[between].value`
      )?.split('..')
      return input === 1 ? val1 : val2
    }

    return prop(
      filterState,
      `${filterName}[${input === 1 ? 'gte' : 'lte'}].value`
    )
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleEnter && handleEnter()
        break
      default:
        break
    }
  }

  const defaultClasses = useStyles()

  return (
    <div className={defaultClasses.container}>
      <TextField
        name={`filter-text-${name}`}
        value={getCurrentValue1() || ''}
        className={clsx(defaultClasses.inputRow, classes.input, 'filter-input')}
        disabled={disabled}
        onChange={handleChange1}
        onKeyDown={handleKeyDown}
        size="small"
        variant="outlined"
        placeholder={titles[0]}
      />
      <TextField
        name={`filter-text-${name}`}
        value={getCurrentValue2() || ''}
        className={clsx(defaultClasses.inputRow, classes.input, 'filter-input')}
        disabled={disabled}
        onChange={handleChange2}
        onKeyDown={handleKeyDown}
        size="small"
        variant="outlined"
        placeholder={titles[1]}
      />
    </div>
  )
}

RangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  titles: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
}
