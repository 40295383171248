import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { List, ListItem } from '@material-ui/core'
import { Status } from 'components/resource/Status'
import { Delete } from 'components/resource/Delete'
import { commonConstants } from '_constants'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'
import routes from 'pages/Pages/routes'
import { useStyles } from 'components/resource/sidebar/Sidebar.styles'
import { SmallDelete } from '../../../components/resource/SmallDelete'
import { SmallStatus } from '../../../components/resource/SmallStatus'

export const Sidebar = ({
  resource,
  titleAccessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  statAccessor = 'stat',
  collectionPath,
  disabled = false,
  handleSyncUpdatedAt,
  actionsGroup1 = [],
  actionsGroup2 = [],
}) => {
  const defaultClasses = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()

  const onDeleteSuccess = () => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(collectionPath)
  }

  const profile = useSelector(state => state.profile)

  const maxDepth = process.env.REACT_APP_PAGE_MAX_DEPTH

  const isSubPageable =
    profile?.openform || (resource.isSubPageable && resource.depth < maxDepth)

  return (
    <div className={defaultClasses.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SmallStatus
          resource={resource}
          accessor={statAccessor}
          disabled={disabled || (!profile?.openform && !resource.isStatable)}
          isSwitch={true}
          handleSyncUpdatedAt={handleSyncUpdatedAt}
        />
        <SmallDelete
          resource={resource}
          accessor={titleAccessor}
          disabled={disabled || (!profile?.openform && !resource.isDeletable)}
          onSuccess={onDeleteSuccess}
          isIcon={true}
        />
      </div>
      {actionsGroup1 &&
        actionsGroup1.map((action, index) => (
          <div key={index} className={defaultClasses.actionsContainer}>
            {typeof action === 'function'
              ? action({ resource: resource })
              : React.cloneElement(action, { resource: resource })}
          </div>
        ))}
      {actionsGroup2 &&
        actionsGroup2.map((action, index) => (
          <div key={index} className={defaultClasses.actionsContainer}>
            {typeof action === 'function'
              ? action({ resource: resource })
              : React.cloneElement(action, { resource: resource })}
          </div>
        ))}
    </div>
  )

  return (
    <List className={defaultClasses.list}>
      {isSubPageable && (
        <ListItem className={defaultClasses.item}>
          <Link
            to={`${routes().new.path}?pid=${resource.uuid}`}
            className={defaultClasses.link}
          >
            {translate('T_MODULE_PAGES_ADD_SUBPAGE')}
          </Link>
        </ListItem>
      )}
      <ListItem className={defaultClasses.item}>
        <Status
          resource={resource}
          accessor={statAccessor}
          disabled={disabled || (!profile?.openform && !resource.isStatable)}
          isSwitch={false}
          handleSyncUpdatedAt={handleSyncUpdatedAt}
        />
      </ListItem>
      <ListItem className={defaultClasses.item}>
        <Delete
          resource={resource}
          accessor={titleAccessor}
          disabled={disabled || (!profile?.openform && !resource.isDeletable)}
          onSuccess={onDeleteSuccess}
        />
      </ListItem>
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
    depth: PropTypes.number.isRequired,
    isSubPageable: PropTypes.bool,
    isStatable: PropTypes.bool,
    isDeletable: PropTypes.bool,
  }).isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleSyncUpdatedAt: PropTypes.func.isRequired,
  actionsGroup1: PropTypes.array,
  actionsGroup2: PropTypes.array,
}
