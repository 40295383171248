import _block from './_block'
import photoSchema from './photo'
import mediaSchema from './media'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/events',
  clone_endpoint: '/api/event/clone',
  access: 'event',
  resource: {
    definition: '#Event',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          /*
          shortLead: {
            description: 'T_GENERAL_LEAD',
            type: 'textarea',
          },

           */
          description: {
            type: 'tinymce',
            description: 'Krótkie wprowadzenie',
            label: 'Krótkie wprowadzenie',
            hint: 'ok. 350 znaków',
          },
          /*
          location: {
            description: 'T_MODULE_EVENT_LOCATION',
          },
          address: {
            description: 'T_MODULE_EVENT_ADDRESS',
          },*/
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/wydarzenia/:slug',
              // [LANGS_IRI.EN]: '/en/events/:slug',
            },
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      showDateFromAlert: {
        type: 'alert',
        description: 'Ustawienia daty znajdują się teraz na dole formularza',
        variant: 'filled',
        severity: 'info',
      },
      /*
      dateFrom: {
        type: 'datetime',
        description: 'T_MODULE_EVENT_DATE_FROM',
        disabled: true,
      },
      duration: {
        description: 'Czas trwania w godzinach',
        disabled: true,
      },*/
      mainLocation: {
        type: 'resource',
        endpoint: '/api/event_locations/select?pagination=false',
        label: 'Główna lokacja',
        titleAccessor: 'nativeTitle',
      },
      /*
      googleMapsUrl: {
        description: 'Lokacja - url do mapy google',
      },
      dateTo: {
        type: 'datetime',
        description: 'T_GENERAL_DATE_TO',
      },*/
      /*
      category: {
        type: 'resource',
        endpoint: `/api/event_categories?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORY',
      },
       */
      categories: {
        type: 'multipleResource',
        endpoint: `/api/event_categories?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORIES',
      },
      suggested: {
        description: 'T_MODULE_EVENT_SUGGESTED',
      },
      adminCanEdit: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
      showInNews: {
        description: 'Pokaż w aktualnościach',
        hint: 'Generuje wpis w aktualnościach',
      },
      generatedNewsData: {
        type: 'autoNewsData',
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/event_block_thumbs',
        '/api/event_block_anchors',
        '/api/event_block_files',
        '/api/event_block_links',
        '/api/event_block_link_thumbs',
        '/api/event_block_list_items',
        '/api/event_block_list_item_thumbs'
      ).types,
    },
    dates: {
      endpoint: '/api/event_dates',
      endpointCollection: '/api/event_dates',
      definition: '#EventDate',
      properties: {
        dateFrom: {
          type: 'datetime',
          description: 'Dodatkowa data',
          validate: ['required'],
        },
        duration: {
          type: 'number',
          description: 'Czas trwania w godzinach',
        },
      },
    },
    cycle: {
      endpoint: '/api/event_dates/generate_cycle',
      endpointCollection: '/api/event_dates?cycle=true&pagination=false',
      definition: '#EventDate',
      properties: {
        dateFrom: {
          type: 'datetime',
          description: 'Początek powtarzania',
          validate: ['required'],
        },
        dateTo: {
          type: 'date',
          description: 'Koniec powtarzania',
          validate: ['required'],
        },
        groupBy: {
          description: '',
          type: 'radio',
          choices: {
            day: 'Dzień',
            week: 'Tydzień',
            month: 'Miesiąc',
            quarter: 'Kwartał',
            year: 'Rok',
          },
          validate: ['required'],
        },
      },
    },
    customLocations: {
      endpoint: '/api/event_date_locations',
      definition: '#EventDateLocation',
      properties: {
        location: {
          type: 'resource',
          endpoint: '/api/event_locations/select?pagination=false',
          label: 'Wybierz lokalizację',
          titleAccessor: 'nativeTitle',
          validate: ['required'],
        },
        date: {
          type: 'resource',
          endpoint: '/api/event_dates?pagination=false',
          label: 'Wybierz datę z listy',
          titleAccessor: 'dateFromHuman',
          validate: ['required'],
          filterByParent: true,
        },
      },
    },
  },
}

export default schema
