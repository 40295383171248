const idNameRouteMapping = {
  get park_news() {
    return require('pages/News/routes').default().index
  },
  get park_events() {
    return require('pages/Event/routes').default().index
  },
  get park_guides() {
    return require('pages/Guides/routes').default().index
  },
  get park_accommodation() {
    return require('pages/Accommodation/routes').default().index
  },
  get park_tourist_announcements() {
    return require('pages/TourismNotice/routes').default().index
  },
  get park_bip() {
    return require('pages/BipPage/routes').default().index
  },
  get park_volunteering() {
    return require('pages/Volunteering/routes').default().index
  },
  get park_shop() {
    return require('pages/ShopProduct/routes').default().index
  },
}

export default idNameRouteMapping
