import React from 'react'
import { SvgIcon } from '@material-ui/core'

function MediaManager(props) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M6.14286 10.1429H11.8571M1 1H17V5.57143H1V1ZM2.14286 5.57143V15.8571C2.14286 16.4883 2.65453 17 3.28571 17H14.7143C15.3455 17 15.8571 16.4883 15.8571 15.8571V5.57143H2.14286Z"
        stroke="#AAAAAA"
        strokeWidth="2"
        style={{ color: 'transparent' }}
      />
    </SvgIcon>
  )
}

export default MediaManager
