import React from 'react'
import PropTypes from 'prop-types'
import { BipVersion } from '../../resource/BipVersion'

export const BipVersionCell = ({
  resource = null,
  endpoint,
  disabled = false,
  onSuccess: customOnSuccess = null,
  customIri = null,
}) => {
  const onSuccess = () => {
    customOnSuccess && customOnSuccess()
  }

  return (
    <BipVersion
      resource={resource}
      endpoint={endpoint}
      disabled={disabled}
      onSuccess={onSuccess}
      customIri={customIri}
    />
  )
}

BipVersionCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  endpoint: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  customIri: PropTypes.string,
}
