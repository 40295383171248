import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { DeleteForeverOutlined } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'
import Trash from '../../_icons/trash'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    color: theme.palette.disabled,
  },
  confirmText: {
    marginTop: 12,
  },
  smallContainer: {
    width: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: '#5A6169',
      fontSize: 14,
    },
  },
  smallDeleteIcon: {
    cursor: 'pointer',
    fontSize: 18,
  },
  smallDeleteIconLabel: {
    cursor: 'pointer',
    paddingLeft: 18,
  },
}))

const styles = () => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Trash />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const SmallDelete = ({
  resource,
  accessor = 'title',
  disabled = false,
  onSuccess = null,
  isIcon = false,
}) => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleDelete = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      resource['@id'],
      'DELETE',
      {},
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('success', 'T_GENERAL_DELETED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(resource)
      },
      error => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        isIcon ? (
          <div className={classes.smallContainer}>
            <DeleteForeverOutlined className={classes.disabled} disabled />
          </div>
        ) : (
          <span className={classes.disabled}>
            {translate('T_GENERAL_DELETE')}
          </span>
        )
      ) : (
        <>
          {isIcon ? (
            <div className={classes.smallContainer}>
              <Trash
                onClick={handleConfirmToggle}
                className={classes.smallDeleteIcon}
              />
              <span
                className={classes.smallDeleteIconLabel}
                onClick={handleConfirmToggle}
              >
                {translate('T_GENERAL_DELETE')}
              </span>
            </div>
          ) : (
            <div className={classes.text} onClick={handleConfirmToggle}>
              {translate('T_GENERAL_DELETE')}
            </div>
          )}
          <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
            <DialogTitle onClose={handleConfirmToggle}>
              {translate('T_GENERAL_DELETE')}
            </DialogTitle>
            <DialogContent dividers>
              {state.isFetching && <Loader align="center" marginBottom={15} />}
              <DialogContentText className={classes.confirmText}>
                {translate('T_GENERAL_DELETE_CONFIRM')}
                {` "${
                  accessor
                    ? typeof accessor === 'function'
                      ? accessor(resource)
                      : prop(resource, accessor)
                    : translate('T_GENERAL_MISSING_TITLE')
                }"?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmToggle}
                disabled={state.isFetching}
                autoFocus
                variant="outlined"
              >
                {translate('T_GENERAL_CANCEL')}
              </Button>
              <Button
                onClick={handleDelete}
                disabled={state.isFetching}
                variant="contained"
                color="primary"
              >
                {translate('T_GENERAL_CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

SmallDelete.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
}
