import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { List, ListItem } from '@material-ui/core'
import { Status } from 'components/resource/Status'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'
import routes from '../routes'
import { useStyles } from 'components/resource/sidebar/Sidebar.styles'
import { HistoryOutlined } from '@material-ui/icons'
import schema from '../../../_schema/tourismNoticeArticle'
import { TourismNoticeVersion } from '../../../components/resource/TourismNoticeVersion'

export const Sidebar = ({
  resource,
  titleAccessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  statAccessor = 'stat',
  collectionPath,
  disabled = false,
  versionEndpoint = '',
  onVersionSuccess,
  handleSyncUpdatedAt,
}) => {
  const defaultClasses = useStyles()

  /*
  const onDeleteSuccess = () => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(collectionPath)
  }
   */

  const profile = useSelector(state => state.profile)

  const customIri = schema.getEndpoint.replace(':uuid', resource?.uuid)

  return (
    <List className={defaultClasses.list}>
      {versionEndpoint && (
        <ListItem
          className={defaultClasses.item}
          style={{ backgroundColor: '#4caf50', color: '#ffffff' }}
        >
          <TourismNoticeVersion
            resource={resource}
            endpoint={versionEndpoint}
            onSuccess={onVersionSuccess}
            disabled={disabled}
            customIri={customIri}
          />
        </ListItem>
      )}
      <ListItem className={defaultClasses.item}>
        <Link
          to={routes().versions.path.replace(':id', resource?.uuid)}
          className={defaultClasses.link}
          style={{ display: 'flex', width: '100%' }}
        >
          {translate('Historia komunikatu')}
          <HistoryOutlined style={{ marginLeft: 'auto' }} />
        </Link>
      </ListItem>
      {statAccessor && (
        <ListItem className={defaultClasses.item}>
          <Status
            resource={resource}
            accessor={statAccessor}
            disabled={disabled || (!profile?.openform && !resource.isStatable)}
            isSwitch={false}
            handleSyncUpdatedAt={handleSyncUpdatedAt}
          />
        </ListItem>
      )}
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
    depth: PropTypes.number,
    isSubPageable: PropTypes.bool,
    isStatable: PropTypes.bool,
    isDeletable: PropTypes.bool,
  }).isRequired,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  versionEndpoint: PropTypes.string,
  onVersionSuccess: PropTypes.func,
  handleSyncUpdatedAt: PropTypes.func.isRequired,
}
