import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '_services/apiUrlProvider'
import { Loader } from 'components/Loader'
import CsvUploadButton from './CsvUploadButton'

export const ControlList = () => {
  const [isFetching, setIsFetching] = React.useState(false)
  const exportAll = () => {
    setIsFetching(true)
    let url = `${getApiUrl()}/api/guide/pdf/download`
    let xtitle = null
    fetch(url, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({
        items: [],
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        setIsFetching(false)
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'lista.csv')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        setIsFetching(false)
        notification('error', 'Error, nie mozna pobrać', e)
      })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card style={{ marginTop: '15px' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Eksport listy
            </Typography>
            <Typography color="textSecondary">
              ( csv, windows-1250, rozdzielony średnikiem )
            </Typography>

            {isFetching ? (
              <Loader style={{ marginTop: 22 }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 15 }}
                onClick={exportAll}
              >
                Pobierz
              </Button>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <CsvUploadButton />
      </Grid>
    </Grid>
  )
}
