import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../pages/News/routes'

export const AutoNewsDataType = ({ name, value }) => {
  if (value?.uuid) {
    return (
      <div>
        <a href={routes().edit.path.replace(':id', value.uuid)}>
          {value?.title}
        </a>
      </div>
    )
  }

  return null
}

AutoNewsDataType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
