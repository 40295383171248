import React, { useRef } from 'react'
import { Button } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Loader } from '../../../components/Loader'
import Card from '@material-ui/core/Card'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'

const CsvUploadButton = () => {
  const fileInputRef = useRef(null)
  const [isFetching, setIsFetching] = React.useState(false)

  const handleButtonClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async event => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('csvFile', file)

      try {
        setIsFetching(true)

        const response = await fetch(`${getApiUrl()}/api/guide/import`, {
          method: 'POST',
          body: formData,
          headers: {
            ...authHeader(),
          },
        })

        const displayResponse = jsonData => {
          if (jsonData?.success) {
            notification('success', jsonData.success)
          }

          if (jsonData?.error) {
            notification('error', jsonData.error)
          }
        }

        if (response.ok) {
          const result = await response.json()
          console.log('File uploaded successfully:', result)
          setIsFetching(false)
          displayResponse(result)
        } else {
          setIsFetching(false)
          console.error(
            'Error uploading file:',
            response.status,
            response.statusText
          )
          displayResponse(await response.json())
        }
      } catch (error) {
        setIsFetching(false)
        console.error('Network error uploading file:', error)
        notification('error', 'Wystąpił błąd podczas przesyłania pliku')
      }
    }
  }

  return (
    <>
      <Card style={{ marginTop: '15px' }}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            Import listy CSV
          </Typography>
          <Typography color="textSecondary">
            ( csv, windows-1250, rozdzielony średnikiem )
          </Typography>

          {isFetching ? (
            <Loader style={{ marginTop: 22 }} />
          ) : (
            <>
              <input
                type="file"
                ref={fileInputRef}
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 15 }}
                onClick={handleButtonClick}
              >
                Prześlij plik
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default CsvUploadButton
