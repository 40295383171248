import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { Button as OriginalButton } from '@material-ui/core'
import { SaveOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'

export const useStyles = makeStyles({
  submit: {
    marginTop: '1.5em',
  },
})

export const SubmitButton = ({
  title = null,
  handleSubmit,
  disabled = false,
  nodeRef = null,
  classes = {},
  customSubmitButtonIcon = null,
}) => {
  const button = (
    <Button
      title={title}
      handleSubmit={handleSubmit}
      disabled={disabled}
      classes={classes}
      customSubmitButtonIcon={customSubmitButtonIcon}
    />
  )

  return nodeRef ? createPortal(button, nodeRef) : button
}

const Button = ({
  title = null,
  handleSubmit,
  disabled = false,
  classes = {},
  customSubmitButtonIcon = null,
}) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.submit, classes.submit)}>
      <OriginalButton
        type="submit"
        variant="contained"
        color="primary"
        size="small"
        startIcon={
          customSubmitButtonIcon ? customSubmitButtonIcon : <SaveOutlined />
        }
        disabled={disabled}
        onClick={handleSubmit}
      >
        {title || translate('T_FORM_SAVE')}
      </OriginalButton>
    </div>
  )
}

SubmitButton.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    submit: PropTypes.string,
  }),
  customSubmitButtonIcon: PropTypes.element,
}
