import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/event'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { translate } from '../../_helpers/translate'
import { DatesCollection } from './component/DatesCollection'
import { EmbeddedCollection } from '../../components/embedded'
import { AddModal } from '../../components/embedded/AddModal'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const defaultData = { parent: iri }

  /*
  <EmbeddedCollection
          endpoint={`${schema.subresources.dates.endpoint}?pagination=false&iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.dates.properties}
          definitionSchema={definitions[schema.subresources.dates.definition]}
          headerTitle={translate('Dodatkowe daty')}
          expandTitle={translate('daty')}
          panelTitle={'Data'}
          titleAccessor={`dateFromHuman`}
          statable={false}
          modalResponse={modalResponse}
          setModalResponse={setModalResponse}
          hideAddButton={true}
        />
        <AddModal
          title={translate('Dodaj datę')}
          definitionSchema={definitions[schema.subresources.dates.definition]}
          itemProperties={schema.subresources.dates.properties}
          defaultData={defaultData}
          endpoint={schema.subresources.dates.endpoint}
          itemSchema={schema}
          setModalResponse={setModalResponse}
        />
   */

  const [modalResponse, setModalResponse] = useState(null)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <div style={{ marginBottom: '20px' }}>
        <DatesCollection
          endpoint={`${schema.subresources.dates.endpoint}?iri[parent]=${iri}&cycle=false&pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.dates.properties}
          definitionSchema={definitions[schema.subresources.dates.definition]}
          headerTitle={translate('Dodatkowe daty')}
          panelTitle={translate('teststring')}
          titleAccessor={`dateFromHuman`}
          statable={false}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.customLocations.endpoint}?pagination=false&iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.customLocations.properties}
          definitionSchema={
            definitions[schema.subresources.customLocations.definition]
          }
          headerTitle={translate('Inne lokalizacje')}
          expandTitle={translate('lokalizacje')}
          panelTitle={'Lokalizacja'}
          titleAccessor={`listTitle`}
          statable={false}
          modalResponse={modalResponse}
          setModalResponse={setModalResponse}
          hideAddButton={true}
        />
        <AddModal
          title={translate('Dodaj lokalizację')}
          definitionSchema={
            definitions[schema.subresources.customLocations.definition]
          }
          itemProperties={schema.subresources.customLocations.properties}
          defaultData={defaultData}
          endpoint={schema.subresources.customLocations.endpoint}
          itemSchema={schema}
          setModalResponse={setModalResponse}
        />
      </div>
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
