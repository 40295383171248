import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { BlockCollection } from 'components/block'
import { Form } from 'pages'
import { Sidebar } from './sidebar'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import { modifyBlockByPage } from './_helpers/modifyBlockByPage'
import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/page'
import routes from './routes'
import { Alert } from '@material-ui/lab'
import { SmallAddButton } from '../../components/resource/SmallAddButton'
import { translate } from '_helpers/translate'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [reses, setReses] = React.useState(null)

  const isNotEditable = useCallback(
    resource => {
      setReses(resource)
      return !profile?.openform && !resource.isEditable
    },
    [profile]
  )

  const blockTypes = useMemo(() => {
    return reses
      ? modifyBlockByPage(schema.subresources.block.types, reses)
      : []
  }, [reses])

  const history = useHistory()
  const handleSuccess = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(routes().index.path)
  }, [history, dispatch])

  const handleSuccessAndStay = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
  }, [dispatch])

  const [parentResource, setParentResource] = useState(null)

  const classes = useStyles()

  const addSubpageAction = (
    <SmallAddButton
      title={translate('T_MODULE_PAGES_ADD_SUBPAGE')}
      path={routes().new.path}
    ></SmallAddButton>
  )

  return (
    <Form
      iri={iri}
      isNotEditable={isNotEditable}
      collectionPath={routes().index.path}
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = modifyPropertiesByPage(
          properties,
          resource,
          schema
        )
        return addOpenformProperties(
          profile?.openform,
          modifiedProperties,
          resource,
          schema
        )
      }}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      sidebar={true}
      sidebarTitleAccessor={`translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`}
      SidebarComponent={Sidebar}
      sidebarActions1={[addSubpageAction]}
      fieldsFullWidth={false}
      width="100%"
      key={iri}
      classes={{ root: classes.form }}
      setParentResource={setParentResource}
    >
      {parentResource?.idName === 'park_tourist_announcement' && (
        <Alert
          variant="filled"
          severity="warning"
          style={{ marginBottom: '10px' }}
        >
          {'Bloki należy dodawać w zakładce Portal => Komunikat turystyczny'}
        </Alert>
      )}
      {blockTypes?.length > 0 && (
        <BlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={blockTypes}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          titleAccessor={
            reses && reses.idName === 'page_declaration_a11y' ? `idName` : null
          }
          showAdd={
            reses && reses.idName === 'page_declaration_a11y' ? false : true
          }
          deleteDisabled={
            reses && reses.idName === 'page_declaration_a11y' ? false : true
          }
          key={iri}
        />
      )}
    </Form>
  )
}
