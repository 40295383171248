import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const droplabs = (
  photoEndpoint,
  blockListItemEndpoint,
  blockListItemEndpointThumbEndpoint,
  definitionRefblockListItemEndpoint,
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.DROPLABS,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_DROPLABS',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        // title: {},
        // text: {
        //   type: 'textarea',
        //   description: 'Opis',
        // },
        text2: {
          description: 'T_GENERAL_BLOCK_TYPE_EMBED_TEXT',
          type: 'textarea',
        },
      },
    },
    listItems: {
      type: 'collection',
      label: 'Elementy',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: definitionRefblockListItemEndpoint,
      endpoint: blockListItemEndpoint,
      statable: true,
      sort: 'order[ord]=ASC',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
            },
            text: {
              label: 'Opis',
              type: 'textarea',
            },
            alt: {
              type: 'string',
              description: 'T_GENERAL_ALT',
            },
          },
        },
        itemId: {
          type: 'string',
          description: 'Unikalna klasa przycisku',
          hint: '"selector" w kodzie embed',
        },
        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: photoEndpoint,
          minDimensions: '683 x 330',
          // thumbs: blockListItemEndpointThumbEndpoint && {
          //   endpoint: blockListItemEndpointThumbEndpoint,
          // },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
      },
    },
  },
})
