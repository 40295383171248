import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import crewSchema from '../crewItem'
import { LANGS_IRI } from '../../_lib/langs'
import FIELD_TYPES from '../../components/form/_helpers/fieldTypes'

export const crew = () => ({
  name: BLOCK_TYPES_IRI.CREW2,
  label: 'Blok z zespołem 2',
  properties: {
    blockAnchor: {
      type: FIELD_TYPES.STRING,
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {
          description: 'Title',
        },
        text: {
          type: FIELD_TYPES.TEXTAREA,
          description: 'Opis',
        },
      },
    },
    crewItems: {
      type: FIELD_TYPES.MULTIPLE_RESOURCE,
      endpoint: `${crewSchema.endpoint}?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      label: 'Pracownicy',
    },
  },
})
