import React, { useMemo } from 'react'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import schema from '_schema/shopPromotion'
import { StringFilter } from '../../components/table/filters/StringFilter'

export const ProductPurchases = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'title',
        header: 'Produkt',
        accessor: `title`,
        filterable: true,
        sortable: true,
        width: '70%',
      },
      {
        columnId: 'Ilość',
        header: 'count',
        accessor: `count`,
        filterable: true,
        sortable: true,
        width: '30%',
      },
    ],
    []
  )

  const today = new Date().toISOString().slice(0, 10)

  return (
    <SchemableCollection
      component={CollectionTable}
      path={`paths.${schema.endpoint}.get`}
      endpoint={'/api/shop_report_product_purchases'}
      definition={schema.resource.definition}
      columns={columns}
      customResourceSchema={schema.resource}
      defaultSorters={{ ord: 'desc' }}
      orderable={false}
      isRowLinkable={false}
      numerable={false}
      autoWidth={false}
      horizontalFilterBar={true}
      paginable={false}
      customFilters={[
        {
          name: 'startDate',
          title: 'Data rozpoczęcia',
          Filter: StringFilter,
          value: today,
        },
        {
          name: 'endDate',
          title: 'Data zakończenia',
          Filter: StringFilter,
          value: today,
        },
      ]}
      parameters={[
        {
          name: 'startDate',
          in: 'query',
          type: 'string',
        },
        {
          name: 'endDate',
          in: 'query',
          type: 'string',
        },
      ]}
    />
  )
}
