import React, { useState } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const CheckboxWithLabel = ({ label, onChange, style = {} }) => {
  const [state, setState] = useState(false)

  const handleChange = event => {
    setState(event.target.checked)
    if (onChange) {
      onChange(event.target.checked)
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox checked={state} onChange={handleChange} color="secondary" />
      }
      label={label}
      labelPlacement="end"
      style={style}
    />
  )
}

export default CheckboxWithLabel
