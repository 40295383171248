import React from 'react'
import { Route } from 'react-router-dom'
import { HomeOutlined } from '@material-ui/icons'
import { SchemableComponent } from 'components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/homeBlock'

const basePath = '/home_blocks'

const routes = () =>
  addRoutesConfig(
    {
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: basePath,
        exact: true,
        icon: HomeOutlined,
      },
    },
    {
      title: 'Strona główna',
      resourceAccess: schema.access,
      // isOpenform: true,
    }
  )

export default routes
