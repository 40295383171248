import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const ResourceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  transTitle = false,
  hostnameFilter = false,
  hostname = null,
  filterByParent = false,
  parent = null,
}) => {
  const [id] = useState(randomHash())

  const [choices, setChoices] = useState(resources || [])

  const handleChange = (e, option) => {
    setValue(name, option ? option.value : null)
    validateField(option ? option.value : null)
  }

  let finalEndpoint = endpoint

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  const valueRef = useRef(getValue(value))

  useEffect(() => {
    validateField(initialValue)
    if (initialValue && choices.length > 0 && !valueRef?.current) {
      setValue(name, initialValue ? initialValue.value : null)
    }
  }, [validateField, initialValue, setValue, name, choices])

  if (hostnameFilter && hostname) {
    finalEndpoint += `&iri[hostname]=${hostname['@id']}`
  }

  if (filterByParent && parent) {
    finalEndpoint += `&iri[parent]=${
      typeof parent === 'object' ? parent['@id'] : parent
    }`
  }

  useEffect(() => {
    if (!endpoint || resources) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      finalEndpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, resources, setChoices, finalEndpoint])

  const options = choices.map(resource => ({
    value: resource['@id'],
    title: transTitle
      ? translate(prop(resource, titleAccessor))
      : prop(resource, titleAccessor),
  }))

  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
    >
      <Autocomplete
        id={id}
        disableClearable={false}
        name={name}
        options={options}
        getOptionLabel={option => (option?.title ? option.title : 'undefined')}
        // fix for autocomplete warning
        // https://github.com/mui/material-ui/issues/18514#issuecomment-606854194
        getOptionSelected={(option, value) => value.value === option.value}
        onChange={handleChange}
        value={
          getValue(value)
            ? {
                value: getValue(value),
                title:
                  options.find(option => option.value === getValue(value))
                    ?.title || '',
              }
            : null
        }
        renderInput={params => (
          <TextField
            {...params}
            label={
              translate(label.text || label) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            variant="outlined"
          />
        )}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        classes={{ root: classes.autocomplete }}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            getValue(value) !== getValue(compareValue) && classes.compareNeq
          )}
        >
          {compareValue &&
            options.find(option => option.value === getValue(compareValue))
              ?.title}
        </div>
      )}
    </FormControl>
  )
}

const getValue = value => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  return value['@id']
}

ResourceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compareValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compare: PropTypes.bool,
  endpoint: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    })
  ),
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  transTitle: PropTypes.bool,
  hostnameFilter: PropTypes.bool,
  filterByParent: PropTypes.bool,
  parent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}
