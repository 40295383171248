import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Checkbox,
  FormControlLabel,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { DragIndicatorOutlined } from '@material-ui/icons'
import { SettingsOutlined } from '@material-ui/icons'
import { Loader } from 'components/Loader'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  settings_container: {
    display: 'flex',
    marginBottom: 5,
    marginLeft: 10,
  },
  settings_paper: {
    padding: 2,
  },
  settings_option: {
    marginTop: 5,
    marginBottom: 5,
    cursor: 'pointer',
    '&> *:first-child': {
      marginRight: 5,
    },
    '&> svg': {
      fontSize: 12,
    },
  },
  settings_option_not_selected: {
    '&> svg': {
      opacity: 0,
    },
  },
  settings_button: {
    cursor: 'pointer',
    backgroundColor: theme.elements.columns_settings.backgroundColor,
    color: theme.elements.columns_settings.cogColor,
  },
  tableContainer: {
    backgroundColor: theme.elements.columns_settings.tableBackgroundColor,
    padding: 0,
  },
  popoverPaper: {
    backgroundColor: '#FAFBFC',
    boxShadow: 'none',
    marginTop: 5,
  },
  tableRow: {
    borderBottom: '1px solid #EFEFF0',
  },
  draggingRow: {
    opacity: 0.5,
    backgroundColor: '#f0f0f0',
    zIndex: 1301,
  },
  centeredCell: {
    textAlign: 'center',
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 0,
  },
  tableCell: {
    padding: 0,
    borderLeft: 0,
  },
  list_checkbox: {
    '&> span.MuiIconButton-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Mui-checked': {
      color: theme.elements.columns_settings.checkedColor,
    },
  },
}))

export const ColumnSettings = ({
  columns,
  setVisibleColumns,
  collectionId = null,
}) => {
  const [availableColumns, setAvailableColumns] = useState([])
  const apiConfig = useSelector(state => state.apiConfig)

  const getStorageKey = () => {
    if (!collectionId) {
      return null
    }

    return `${apiConfig?.hostname?.name}_${collectionId}`
  }

  const getStorageKeyRef = useRef(getStorageKey)

  const getFromLocalStorage = key => {
    const storedValue = localStorage.getItem(key)
    if (storedValue) {
      return JSON.parse(storedValue)
    }

    return null
  }

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  useEffect(() => {
    let newColumns = []
    let localColumns = null
    const storageKey = getStorageKeyRef.current()

    if (storageKey && getFromLocalStorage(storageKey)) {
      localColumns = getFromLocalStorage(storageKey)
    } else {
      localColumns = columns
    }

    let missingColumns = columns.filter(
      column =>
        !localColumns.find(
          localColumn => localColumn.columnId === column.columnId
        )
    )

    localColumns.forEach(column => {
      if (!column?.excluded) {
        columns.forEach(originalColumn => {
          if (originalColumn.columnId === column.columnId) {
            originalColumn.hidden = column.hidden
            newColumns.push(originalColumn)
          }
        })
      }
    })

    missingColumns.forEach(column => {
      newColumns.push({
        ...column,
        hidden: false,
      })
    })

    setAvailableColumns(newColumns)
    setVisibleColumns(newColumns)
  }, [columns, setVisibleColumns])

  //const visibleJournalAdminColumns = columns.map(obj => obj.accessor)

  const [settingsAnchorEl, setSettingsAnchorEl] = useState()

  const handleSettingsOpen = e => {
    setSettingsAnchorEl(e.currentTarget)
  }

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null)
  }

  const isSettingsOpen = Boolean(settingsAnchorEl)

  const handleOptionClick = option => () => {
    let updated = [...availableColumns]
    const found = updated.find(visible => visible.columnId === option.columnId)

    if (found) {
      found.hidden = !found.hidden
    }

    setVisibleColumns(updated)
    const storageKey = getStorageKeyRef.current()
    if (storageKey) {
      saveToLocalStorage(storageKey, updated)
    }
  }

  /*
  <Paper elevation={0} className={classes.settings_paper}>
          {availableColumns.map(column => (
            <div
              className={clsx(
                classes.settings_option,
                true ===
                  visibleJournalAdminColumns.find(
                    visible => visible.columnId === column['columnId']
                  )?.hidden && classes.settings_option_not_selected
              )}
              onClick={handleOptionClick(column)}
              key={column['columnId']}
            >
              <DoneOutlined /> {column.header}
            </div>
          ))}
        </Paper>
   */

  const classes = useStyles()

  const DragHandle = SortableHandle(() => (
    <DragIndicatorOutlined style={{ cursor: 'grab' }} />
  ))

  const SortableItem = SortableElement(({ item }) => (
    <TableRow>
      <TableCell className={classes.centeredCell}>
        <DragHandle />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!item.hidden}
              onChange={handleOptionClick(item)}
              name="checkedB"
              color="secondary"
            />
          }
          label={item.header}
          className={classes.list_checkbox}
        />
      </TableCell>
    </TableRow>
  ))

  const SortableList = SortableContainer(({ items }) => {
    return (
      <TableBody>
        {items.map((item, index) => (
          <SortableItem key={item.columnId} index={index} item={item} />
        ))}
      </TableBody>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const reordered = arrayMove(availableColumns, oldIndex, newIndex)
    setAvailableColumns(reordered)
    setVisibleColumns(reordered)
    const storageKey = getStorageKeyRef.current()
    if (storageKey) {
      saveToLocalStorage(storageKey, reordered)
    }
  }

  return availableColumns.length ? (
    <div className={classes.settings_container}>
      <SettingsOutlined
        className={classes.settings_button}
        onClick={handleSettingsOpen}
      />
      <Popover
        open={isSettingsOpen}
        anchorEl={settingsAnchorEl}
        onClose={handleSettingsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ className: classes.popoverPaper }}
      >
        <TableContainer className={classes.tableContainer}>
          <Table>
            <SortableList
              items={availableColumns}
              onSortEnd={onSortEnd}
              useDragHandle
              helperClass={classes.draggingRow}
            />
          </Table>
        </TableContainer>
      </Popover>
    </div>
  ) : (
    <Loader />
  )
}
