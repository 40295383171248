import React from 'react'
import { SvgIcon } from '@material-ui/core'

function Contest(props) {
  return (
    <SvgIcon {...props} width="20" height="22" viewBox="0 0 20 22" fill="none">
      <path
        d="M18.9418 17.7262L15.4069 11.134C16.0774 10.101 16.4692 8.8637 16.4692 7.53424C16.4692 3.93128 13.5986 1 10.0701 1C6.54169 1 3.67106 3.93128 3.67106 7.53424C3.67106 8.81259 4.03384 10.0054 4.65774 11.0137L1.05823 17.7262C0.967379 17.8957 0.983928 18.1041 1.10024 18.2566C1.21655 18.4088 1.41108 18.4747 1.59357 18.4272L4.51953 17.6485L5.60201 20.6817C5.66565 20.8603 5.82581 20.9842 6.01146 20.9986C6.02348 20.9995 6.03553 21 6.04744 21C6.21965 21 6.37945 20.9043 6.46313 20.7485L9.99999 14.1527L13.5369 20.7485C13.6206 20.9043 13.7804 21 13.9526 21C13.9645 21 13.9765 20.9995 13.9886 20.9986C14.1742 20.9842 14.3343 20.8603 14.398 20.6817L15.4805 17.6485L18.4065 18.4272C18.5892 18.4747 18.7832 18.4085 18.8998 18.2566C19.0161 18.1042 19.0326 17.8957 18.9418 17.7262ZM10.0701 1.96803C13.0758 1.96803 15.5212 4.46494 15.5212 7.53424C15.5212 10.6035 13.0758 13.1004 10.0701 13.1004C7.06442 13.1004 4.61907 10.6035 4.61907 7.53424C4.61907 4.46494 7.06444 1.96803 10.0701 1.96803ZM6.13435 19.3459L5.26259 16.9033C5.17882 16.6684 4.93359 16.5365 4.69774 16.6006L2.41727 17.2075L5.28293 11.8636C6.23405 12.9589 7.53867 13.7263 9.01284 13.9777L6.13435 19.3459ZM15.3023 16.6006C15.0668 16.5377 14.8212 16.6687 14.7374 16.9033L13.8656 19.3459L10.9985 13.9989C12.4805 13.778 13.7989 13.0366 14.7702 11.9625L17.5827 17.2075L15.3023 16.6006Z"
        fill="#AAAAAA"
        stroke="#AAAAAA"
      />
    </SvgIcon>
  )
}

export default Contest
