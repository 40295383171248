import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form as FormComponent } from 'components/form'
import { applyProperties } from '_helpers/applyProperties'
import { collectionActions } from '_actions'
import { commonConstants } from '_constants'

export const BlankForm = ({
  resource = null,
  defaultData = {},
  definitionSchema,
  definitions,
  customResourceSchema,
  modifyProperties = false,
  iri = null,
  isCurrentResource = true,
  method,
  title = null,
  url,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  handleSuccess: customHandleSuccess = null,
  handleSuccessAndStay: customHandleSuccessAndStay = null,
  handleCancel: customHandleCancel = null,
  showSubmitButton = true,
  showSubmitAndStayButton = true,
  showCancelButton = true,
  fetchCompareResource = false,
  fieldsFullWidth = true,
  width = 300,
  classes = {},
  buttonContainerRef = null,
  fieldNodeRefs = {},
  buttonsFixed = true,
  onlyPassedProperties = false,
  customHandleSubmit = null,
  submitBtnTitle = null,
  disabled = false,
  readOnly = false,
  reloadParent = false,
  setReloadParent = null,
  modifiedCallback = null,
  setParentResource = null,
}) => {
  const properties = applyProperties(
    customResourceSchema.properties,
    definitionSchema?.properties,
    'missing'
  )

  const modifiedProperties = modifyProperties
    ? modifyProperties(properties, resource, definitionSchema.properties)
    : properties

  const dispatch = useDispatch()
  const history = useHistory()

  const defaultHandleSuccess = () => {
    if (!iri && storeCollectionId) {
      dispatch(collectionActions.resetConfig(storeCollectionId))
    }
    collectionPath && history.push(collectionPath)
  }

  const defaultHandleSuccessAndStay = resource => {
    if (iri) {
      return
    }
    editPath && history.push(editPath.replace(':id', resource['uuid']))

    if (modifiedCallback) {
      modifiedCallback(resource)
    }
  }

  const defaultHandleCancel = () => {
    collectionPath && history.push(collectionPath)
  }

  const handleSuccess = resource => {
    customHandleSuccess
      ? customHandleSuccess(resource)
      : defaultHandleSuccess(resource)
  }

  const handleSuccessAndStay = resource => {
    customHandleSuccessAndStay
      ? customHandleSuccessAndStay(resource)
      : defaultHandleSuccessAndStay(resource)

    isCurrentResource &&
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      })

    if (modifiedCallback) {
      modifiedCallback(resource)
    }

    if (setParentResource) {
      setParentResource(resource)
    }
  }

  const handleCancel = () => {
    customHandleCancel ? customHandleCancel() : defaultHandleCancel()
  }

  return (
    <FormComponent
      title={title}
      url={url}
      method={method}
      properties={modifiedProperties}
      resource={resource}
      defaultData={defaultData}
      fieldsFullWidth={fieldsFullWidth}
      width={width}
      definitions={definitions}
      handleSubmit={customHandleSubmit}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      handleCancel={handleCancel}
      showSubmitButton={showSubmitButton}
      showSubmitAndStayButton={showSubmitAndStayButton}
      showCancelButton={showCancelButton}
      classes={classes}
      buttonContainerRef={buttonContainerRef}
      fieldNodeRefs={fieldNodeRefs}
      buttonsFixed={buttonsFixed}
      fetchCompareResource={fetchCompareResource}
      onlyPassedProperties={onlyPassedProperties}
      submitBtnTitle={submitBtnTitle}
      disabled={disabled}
      readOnly={readOnly}
      tinymceFilesEndpoint={
        definitionSchema?.properties?.filesUrlProviderEndpoint
      }
      definitionSchema={definitionSchema}
      reloadParent={reloadParent}
      setReloadParent={setReloadParent}
      modifiedCallback={modifiedCallback}
      setParentResource={setParentResource}
    />
  )
}

BlankForm.propTypes = {
  resource: PropTypes.object,
  defaultData: PropTypes.object,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  modifyProperties: PropTypes.func,
  iri: PropTypes.string,
  isCurrentResource: PropTypes.bool,
  method: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  collectionPath: PropTypes.string,
  editPath: PropTypes.string,
  storeCollectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSuccess: PropTypes.func,
  handleSuccessAndStay: PropTypes.func,
  handleCancel: PropTypes.func,
  showSubmitAndStayButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  fetchCompareResource: PropTypes.bool,
  fieldsFullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  buttonContainerRef: PropTypes.object,
  fieldNodeRefs: PropTypes.object,
  buttonsFixed: PropTypes.bool,
  onlyPassedProperties: PropTypes.bool,
  reloadParent: PropTypes.bool,
  setReloadParent: PropTypes.func,
  modifiedCallback: PropTypes.func,
  setParentResource: PropTypes.func,
}
