import React from 'react'
import PropTypes from 'prop-types'
import { Sidebar } from './Sidebar'

export const SidebarEnableTrans = ({
  resource,
  versionable = false,
  titleAccessor = 'title',
  collectionPath,
  disabled = false,
  handleSyncUpdatedAt = null,
}) => (
  <Sidebar
    resource={resource}
    statable={true}
    versionable={versionable}
    titleAccessor={titleAccessor}
    collectionPath={collectionPath}
    disabled={disabled}
    statTranslations={{
      publish: 'T_GENERAL_ENABLE',
      unpublish: 'T_GENERAL_DISABLE',
    }}
    handleSyncUpdatedAt={handleSyncUpdatedAt}
  />
)

SidebarEnableTrans.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  versionable: PropTypes.bool,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  versionEndpoint: PropTypes.string,
  onVersionSuccess: PropTypes.func,
  handleSyncUpdatedAt: PropTypes.func,
}
