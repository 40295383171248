import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { styles as translationTabStyles } from 'components/form/fields/translation/TranslationTab.styles'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { useSelector } from 'react-redux'
import { notification } from '../../../_helpers/notification'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    width: '100%',
  },
  label: {
    position: 'relative',
    marginBottom: 15,
    transform: 'none',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const TinymceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  formWidth = '100%',
  translationLang = null,
  filesUrlProviderEndpoint = null,
  tinymceParentUuid = null,
  height = 300,
}) => {
  const [id] = useState(randomHash())
  const theme = useTheme()

  const apiConfig = useSelector(state => state.apiConfig)

  const handleChange = content => {
    setValue(name, content)
    validateField(content)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(
    () => {
      if (initialValue) {
        validateField(initialValue)
      }

      if (!value && initialValue) {
        setValue(name, initialValue)
      }
    },
    // eslint-disable-next-line
    [validateField, initialValue, setValue, name]
  )

  const classes = useStyles()

  /*
  const getFilesLinks = async () => {
    try {
      const response = await fetch(
        `${getApiUrl()}${filesUrlProviderEndpoint}`,
        {
          method: 'GET',
          headers: {
            ...authHeader(),
          },
        }
      )

      if (!response.ok) {
        notification(
          'error',
          'T_FRONT_PREVIEW_ERROR_TEXT',
          'T_FRONT_PREVIEW_ERROR_TITLE'
        )
        return '' // or handle the error according to your logic
      }

      return await response.json()
    } catch (error) {
      // Handle fetch errors
      console.error('Error fetching data:', error)
      throw error // Propagate the error to the caller if needed
    }
  }
   */

  const langUrl = `${window.location.origin.replace('admin.', '')}/tinymce/${
    process.env.REACT_APP_LOCALE
  }.js`

  let isFetching = false

  return (
    <FormControl
      error={renderError && !!error}
      required={validators && validators.includes('required')}
      disabled={disabled}
      className={classes.root}
    >
      <InputLabel htmlFor={id} className={classes.label}>
        <span style={label?.color && { color: label?.color }}>
          {translate(label?.text || label)}
        </span>
      </InputLabel>
      {apiConfig.tinymce_key && (
        <Editor
          apiKey={apiConfig.tinymce_key}
          disabled={disabled}
          id={id}
          tinymceScriptSrc={
            'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js'
          }
          init={{
            menubar: 'file edit view insert',
            force_br_newlines: true,
            force_p_newlines: false,
            forced_root_block: '',
            entity_encoding: 'raw',
            language: process.env.REACT_APP_LOCALE,
            language_url: langUrl,
            paste_as_text: true,
            image_advtab: true,
            plugins: [
              'autolink lists link charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime paste table',
            ],
            contextmenu: 'link | copy paste',
            toolbar:
              'insertfile undo redo | styleselect | ' +
              'bold italic | ' + //alignleft aligncenter alignright alignjustify outdent indent|
              'bullist numlist outdent indent| caption link unlink charmap' +
              'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
            extended_valid_elements:
              'span[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|' +
              'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
              'onkeydown|onkeyup],script[charset|defer|language|src|type]',
            style_formats: [
              {
                title: 'Inline',
                items: [
                  { title: 'Bold', icon: 'bold', inline: 'strong' },
                  { title: 'Italic', icon: 'italic', inline: 'em' },
                  {
                    title: 'Underline',
                    icon: 'underline',
                    inline: 'span',
                    styles: { 'text-decoration': 'underline' },
                  },
                  {
                    title: 'Strikethrough',
                    icon: 'strikethrough',
                    inline: 'span',
                    styles: { 'text-decoration': 'line-through' },
                  },
                  { title: 'Superscript', icon: 'superscript', inline: 'sup' },
                  { title: 'Subscript', icon: 'subscript', inline: 'sub' },
                  { title: 'Code', icon: 'code', inline: 'code' },
                ],
              },
              {
                title: 'Blocks',
                items: [
                  { title: 'Paragraph', block: 'p' },
                  { title: 'Blockquote', block: 'blockquote' },
                  { title: 'Div', block: 'div' },
                  { title: 'Pre', block: 'pre' },
                ],
              },
              {
                title: 'Headings',
                items: [
                  { title: 'Paragraph', block: 'p', classes: 'heading' },
                  { title: 'h2', block: 'h2', classes: 'heading' },
                  { title: 'h3', block: 'h3', classes: 'heading' },
                  { title: 'h4', block: 'h4', classes: 'heading' },
                  { title: 'h5', block: 'h5', classes: 'heading' },
                  { title: 'h6', block: 'h6', classes: 'heading' },
                ],
              },
              // {
              //   title: 'Alignment',
              //   items: [
              //     {
              //       title: 'Left',
              //       icon: 'alignleft',
              //       block: 'div',
              //       styles: { 'text-align': 'left' },
              //     },
              //     {
              //       title: 'Center',
              //       icon: 'aligncenter',
              //       block: 'div',
              //       styles: { 'text-align': 'center' },
              //     },
              //     {
              //       title: 'Right',
              //       icon: 'alignright',
              //       block: 'div',
              //       styles: { 'text-align': 'right' },
              //     },
              //     {
              //       title: 'Justify',
              //       icon: 'alignjustify',
              //       block: 'div',
              //       styles: { 'text-align': 'justify' },
              //     },
              //   ],
              // },
            ],
            paste_text_sticky: true,
            paste_text_sticky_default: true,
            browser_spellcheck: true,
            width: `calc(${formWidth} - ${
              translationLang
                ? translationTabStyles.root?.paddingLeft +
                  translationTabStyles.root?.paddingRight
                : 0
            }px)`,
            height: height,
            skin: theme.palette.type === 'dark' ? 'oxide-dark' : '',
            content_css: theme.palette.type === 'dark' ? 'oxide-dark' : '',
            file_picker_types: 'file',
            file_picker_callback: function(callback, value, meta) {
              if (isFetching) {
                notification(
                  'warning',
                  'Wgrywanie pliku jeszcze się nie zakończyło',
                  'Błąd'
                )
              }

              if (!tinymceParentUuid) {
                notification(
                  'warning',
                  'Funkcja będzie dostępna po zapisaniu formularza',
                  'Błąd'
                )
                return
              }

              const input = document.createElement('input')
              input.setAttribute('type', 'file')
              input.setAttribute('accept', 'image/*, .pdf')

              input.onchange = function() {
                const file = this.files[0]
                const reader = new FileReader()

                reader.onload = function() {
                  const id = 'blobid' + new Date().getTime()
                  const blobCache =
                    window.tinymce.activeEditor.editorUpload.blobCache
                  const base64 = reader.result.split(',')[1]
                  const blobInfo = blobCache.create(id, file, base64)
                  blobCache.add(blobInfo)

                  const formData = new FormData()
                  formData.append('file', blobInfo.blob())
                  formData.append('related', tinymceParentUuid)

                  const headers = new Headers({
                    ...authHeader(),
                  })

                  isFetching = true
                  notification('default', 'Wgrywanie pliku w trakcie', 'Info')

                  fetch(`${getApiUrl()}/api/media/wysiwyg`, {
                    method: 'POST',
                    body: formData,
                    headers: headers,
                  })
                    .then(response => response.json()) // Assuming response is JSON
                    .then(data => {
                      // Callback function with blob URI and additional data
                      callback(data.url, {
                        text: data.originalName,
                        title: data.originalName,
                      })
                      isFetching = false
                      notification('success', 'Pomyślnie wgrano plik', 'Sukces')
                    })
                    .catch(error => {
                      // Handle errors
                      console.error('Error:', error)
                    })
                }
                reader.readAsDataURL(file)
              }

              input.click()
            },
            /*
          link_list: function(success) {
            // called on link dialog open
            if (filesUrlProviderEndpoint) {
              const links = getFilesLinks()
              links
                .then(data => {
                  success(data)
                })
                .catch(error => {
                  console.error(error)
                })
            } else {
              success([])
            }
          },*/
          }}
          textareaName={name}
          value={value}
          onEditorChange={handleChange}
        />
      )}
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </FormControl>
  )
}

TinymceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translationLang: PropTypes.string,
  tinymceParentUuid: PropTypes.string,
  height: PropTypes.number,
}
